
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue'
import ClassroomModel from '@/models/ClassroomModel'
import ModalKuis from '@/main/components/Modals/ModalKuis.vue'
import axios from 'axios'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'Detail Kelas',
  setup() {
    const toast = useToast()
    return {
      toast,
    }
  },
  components: {
    ModalKuis,
  },
  data() {
    return {
      classroomCode: this.$route.params.classroom_code as string,
      paginationMember: {
        perPage: 10 as number,
        lastPage: 2 as number,
        total: 0 as number,
        page: 1 as number,
        from: 0 as number,
        to: 0 as number,
      },
      search: '' as string,
      members: [] as any,
      isLoadingClassrooms: false,
      loadingMember: false,
      totalTask: 0 as number,
      memberLog: [] as any,
      memberLogFilter: [] as any,
      taskId: this.$route.query.task as string,
      isLoading: false,
      tasks: [] as any,
      selectedTask: ' ' as any,
      selectedUserSubmission: {} as any,
      showModalReview: false,
      formReview: {
        feedback: '' as string,
        score: 0 as number,
      },
      showModalReviewDone: false,
      showModalReject: false,
      showPreview: false,
    }
  },
  async mounted() {
    await this.getMentorTask()
    // this.getClassroomMember()
    this.getUserSubmission()
    if (this.taskId) {
      this.selectedTask = this.taskId
      this.changeTask()
    }
  },
  computed: {
    checkFormReview(): boolean {
      if (this.formReview.score > 0 && this.formReview.score <= 100) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    changeTask() {
      this.$router.push(
        '/mentor/classroom/' +
          this.classroomCode +
          '/submission?task=' +
          this.selectedTask
      )
      // console.log(this.selectedTask)
      if (this.memberLog.length > 0) {
        this.memberLog.forEach((el: any, i: any) => {
          if (el.user_submissions.length > 0) {
            const findTask = el.user_submissions.filter((e: any) => {
              if (e.task_id == this.selectedTask) {
                return e
              }
            })
            if (findTask.length > 0) {
              el.selectedTask = findTask[0]
            } else {
              el.selectedTask = null
            }
          }
        })
      }
    },
    async getMentorTask() {
      this.isLoading = true
      await ClassroomModel.getMentorTaskList(this.classroomCode)
        .then(res => {
          this.isLoading = false
          if (res.success) {
            this.tasks = res.data.tasks
            // console.log('tugas', this.tasks)
          }
        })
        .catch(err => {
          this.isLoading = false
          console.log(err)
        })
    },
    async getUserSubmission() {
      this.loadingMember = true
      if (this.classroomCode) {
        try {
          const {
            success,
            data,
            pages,
          } = await ClassroomModel.getMentorClassroomMember(
            this.classroomCode,
            this.search,
            this.paginationMember.perPage,
            this.paginationMember.page
          )
          if (success) {
            this.loadingMember = false
            this.paginationMember.total = pages.total
            this.paginationMember.from = pages.from
            this.paginationMember.to = pages.to
            this.paginationMember.lastPage = Number(pages.last_page)
            if (data.length > 0) {
              this.totalTask = data[0]?.user_quiz
            }
            if (data.length > 0) {
              data.forEach((el: any, i: any) => {
                if (el.user_submissions.length > 0) {
                  const findTask = el.user_submissions.filter((e: any) => {
                    if (e.task_id == this.selectedTask) {
                      return e
                    }
                  })
                  if (findTask.length > 0) {
                    el.selectedTask = findTask[0]
                  } else {
                    el.selectedTask = null
                  }
                }
              })
            }
            this.memberLog = data
          } else {
            this.$router.push('/mentor/classroom')
          }
        } catch (err) {
          this.$router.push('/mentor/classroom')
        }
      }
    },
    onPage(event: any) {
      this.paginationMember.page = event.page + 1
      this.paginationMember.perPage = event.rows
      this.getUserSubmission()
    },
    openUserSubmission(data: any) {
      // console.log(data)
      this.formReview.feedback = data.selectedTask.feedback
      this.formReview.score = data.selectedTask.score
      this.showModalReview = true
      this.selectedUserSubmission = data
    },

    closeModalReview() {
      this.showModalReview = false
    },

    getNameFile(file: any) {
      const splitFile = file.split('/')
      const countArray = splitFile.length
      const name = splitFile[countArray - 1]
      return name
    },

    isNotLink(file: any) {
      return file.includes('assets.sekolahdesain.id')
    },

    downloadFile(file: any) {
      if (!this.isNotLink(file)) {
        window.open('https://' + file, '_blank')
      } else {
        const url = 'https://' + file
        const splitFile = url.split('/')
        const countArray = splitFile.length
        const name = splitFile[countArray - 1]
        axios
          .get(url, {
            responseType: 'arraybuffer',
          })
          .then(response => {
            this.forceFileDownload(response, name)
          })
          .catch(() => {
            window.open('https://' + file, '_blank')
          })
      }
    },

    forceFileDownload(response: any, title: any) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },

    openModalReject() {
      this.showModalReject = true
    },

    closeModalReject() {
      this.showModalReject = false
    },

    async reject() {
      const { success } = await ClassroomModel.rejectMemberSubmission(
        this.selectedUserSubmission.selectedTask.id
      )
      if (success) {
        this.showModalReject = false
        this.selectedUserSubmission = {}
        this.getUserSubmission()
        this.closeModalReview()
        this.toast.success('Berhasil menolak penilaian')
      }
    },

    async submitReview() {
      this.formReview.score = Number(this.formReview.score)
      try {
        const { success } = await ClassroomModel.scoringMemberSubmission(
          this.selectedUserSubmission.selectedTask.id,
          this.formReview
        )
        if (success) {
          this.showModalReviewDone = true
          this.selectedUserSubmission = {}
          this.getUserSubmission()
          this.closeModalReview()
        }
      } catch (error) {
        console.log(error)
      }
    },

    closeModalReviewDone() {
      this.showModalReviewDone = false
    },

    openPreview(file: any) {
      const isImage = file.toLowerCase().substr(file.length - 3)
      if (isImage == 'png' || isImage == 'jpg' || isImage == 'peg') {
        this.showPreview = true
      } else {
        window.open('https://' + file, '_blank')
      }
    },

    closePreview() {
      this.showPreview = false
    },
  },
})
